import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import MyPets from 'src/components/MyPets'
import SEO from 'src/components/shared/SEO'

const MyPetsPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="My Pets" location={location} />
    <Layout location={location}>
      <MyPets queryString={location.search} />
    </Layout>
  </>
)

export default MyPetsPage
